@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.14.0/css/fontawesome.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.14.0/css/solid.min.css");

$gradient-light: rgba(0,85,141,1);
$gradient-dark: rgb(0,12,33);
$gradient-diagonal: rgba(255,255,255,0.1);
$transparent: rgba(255,255,255, 0);
$primary-color: #042851;
$secondary-color: #EF5033;

#app-container {
  overflow-x: hidden !important;
}

.message-chat .ant-comment-inner {
  padding: 4px 0;
}

.message-chat {
  background: transparent;
}

.ant-tooltip-inner .ant-badge {
  display: none;
}

#app-container #app-header {
  position: fixed;
  width: 100%;
  z-index: 1000;
}

#app-container #app-content {
  margin-top: 64px;
  background: $gradient-dark;
  background: linear-gradient($gradient-dark 0%, $gradient-light 100%);
}

#app-container.agenda #app-content {
  margin-top: 64px;
  background: $gradient-dark;
  background: radial-gradient(circle at top right, $gradient-light 0%, $gradient-dark 100%);
}

#front-content,
#loading-page {
  background: $gradient-dark;
  background: url('../images/BG_NEW.jpg') center center;

  background-repeat: no-repeat;
  background-size: cover;
}

#app-container #app-header .ant-menu {
  background: transparent;
  display: flex;
  margin-left: auto;
}

#app-container #app-header .ant-menu .ant-menu-item {
  padding: 0 12px;
  margin: auto 0.5rem;
  border-radius: 100%;
  width: 40px;
}

#app-container #app-header .ant-menu .ant-menu-item-selected {
}

#app-container #app-header .ant-menu-inline-collapsed {
  width: auto;
}

#app-container #app-header .logo-image {
  margin: auto 0 auto 0;
  width: 6rem;
}

#clock {
  font-weight: lighter;
  font-size: 1.2rem;
}

#clock,
#connection {
  padding: 0 16px;
}


#app-container #app-header {
  background: $gradient-dark;
  background: radial-gradient(circle at top, $gradient-light 0%, $gradient-dark 100%);
  border-bottom: 1px solid white;
}

#app-container.admin #app-header .logo-image {
  margin-bottom: auto;
}

#app-container.admin #app-header .isAdmin {
  margin-top: 25px;
  margin-left: -85px;
  position: relative;
  text-align: center;
  color: #ffffffaa;
  font-weight: normal;
}

#app-container .ant-layout-sider + .ant-layout-content {
  /*margin-left: 80px;*/
}

#app-container .ant-layout-sider-children {
  display: flex;
  flex-direction: column;
}

#app-container .ant-layout-sider .clock-digital {
  margin-top: auto;
  margin-bottom: 10px;
  font-weight: lighter;
  font-size: 1.2rem;
}

#app-container header.ant-layout-header {
  background-color: #fff;
  display: flex;
  align-items: center;
  border-bottom: #a3a1a1 1px solid;
}

#app-container .ant-page-header-back-button {
  color: black;
}

#app-container header.ant-layout-header .ant-typography {
  text-align: center;
  margin: auto;
}

.admin-wrapper {
  margin: 2rem 3rem;
}

#app-container #image-background::before,
#app-container #image-background::after {
  background-size: auto;
  background-repeat: repeat;
}

.video-js.vjs-fluid,
.video-js.vjs-16-9,
.video-js.vjs-4-3,
video.video-js,
video.vjs-tech {
  max-height: calc(100vh - 120px);
  position: relative !important;
  width: 100%;
  height: auto !important;
  max-width: 100% !important;
  padding-top: 0 !important;
  line-height: 0;
}

#app-container.login {
  color: #fff !important;
}

#app-container.login .ant-typography,
#app-container.login .ant-layout-footer {
  color: #fff !important;
}

#app-container.login h1.ant-typography {
  text-align: center;
}

#app-container.login .ant-btn {
  width: 15rem;
  margin: auto;
  display: block;
}

#app-container.login .login-form .ant-input-affix-wrapper,
#app-container.login .login-form .ant-input {
  background: transparent;
  color: white;
}

#app-container.login #app-content {
  padding-bottom: 120px;
}

#app-container.login .login-form-wrapper {
  width: 50em;
  max-width: 100%;
  margin: .5rem;

  .login-footer {
    text-align: center;
    background-color: transparent;
    margin-top: 1.5rem;
  }

}

.login-form {
  width: 25rem;
  max-width: 100%;
  padding: 0 5rem;
  margin: auto !important;
}

#chat-form {
  margin-top: .3rem;
}

#chat-form .ant-form-item-with-help {
  margin-bottom: 0;
}

#chat-form .ant-form-item-with-help .ant-form-item-explain {
  display: none;
}

#chat-form input[type='text'] {
  color: #000;
}

#small-badge .ant-badge-count {
  min-width: 12px;
  height: 12px;
  padding: 0 2px;
  font-size: 8px;
  line-height: 12px;
}

#clock > div > span {
  font-size: 2rem;
}

#video-page-container {

  .vjs-control-bar {
    font-size: 151%
  }

  .vjs-control-bar .vjs-control {
    order: 5;
  }

  .vjs-control-bar .vjs-play-control {
    order: 1;
    margin-left: 1rem;
  }

  .vjs-control-bar .vjs-fullscreen-control {
    order: 2;
  }

  .vjs-control-bar .vjs-volume-panel {
    order: 2;
  }

  .vjs-control-bar .icon-sync {
    margin-left: auto !important;
  }

  .vjs-progress-control {
    display: none !important;
  }
}

#video-page-container.hideClap .icon-clap,
#video-page-container.hideDirect .icon-direct,
#video-page-container.hideChat .icon-chat,
#video-page-container.hideChat #video-chat,
#video-page-container.hideQuiz .quiz-icon,
#video-page-container.hideWordCloud .wordcloud-icon,
#video-page-container.hideQuiz .icon-help-circled {
  display: none;
}

.ant-card.active {
  box-shadow: 1px 1px 2px 2px $secondary-color;
}

#dashboard, #main-dashboard, #meetings-rows {
  .ant-card {
    background: $transparent;
    background: linear-gradient(145deg,
            $transparent 58%,
            $transparent 62%,
            $gradient-diagonal 62%,
            $gradient-diagonal 69%,
            $transparent 69%,
            $transparent 70%,
            $gradient-diagonal 70%,
            $gradient-diagonal 77%,
            $transparent 77%,
            $transparent 100%
    );
    color: #ffffff;
    box-shadow: 0 0 6px 3px inset rgba(255, 255, 255, 0.4);
    min-height: 8rem;
  }

  .ant-card .icon-col {
    font-size: 5rem;
    line-height: 5rem;
  }

  .ant-card .ant-typography {
    color: #ffffff;
  }
}

#main-dashboard {
  .ant-card {
    min-height: 10rem;
  }

  .ant-card .icon-col {
    font-size: 7rem;
    line-height: 7rem;
  }
}

.fullHeight {
  height: calc(8rem - 48px);
}

.leftAlign, .leftAlign > * {
  text-align: left !important;
}

#home-button {
  width: 2.2rem;
  height: 2.2rem;
  background: white;
  border-radius: 100%;
  text-align: center;
  line-height: 2.2rem;
  font-size: 1.6rem;
  margin-left: auto;
  display: block;
}

#current-result .ant-result-icon .anticon {
    color: #fff !important;
    font-size: 6rem;
}

#current-result .ant-result-title {
    color: #fff !important;
}

.message-list-inner-wrapper {
  padding: .5rem;
  height: 100%;
  //margin: 1rem auto;
}

#message-wrapper {
  height: calc(100vh - 90px - 1rem);
  overflow: auto;
  padding: 0;
  background: white;

  .message-item {
    padding: 0 .1rem;
    margin-bottom: .5rem;
  }
}

#coffee-break-chat {
  height: calc(100vh - 69px - 80px - 64px + 0.5rem);
  overflow: auto;
  padding: 0;

  .message-list-inner-wrapper {
    width: 100%;
    margin: 0;
  }

  .message-list-inner-wrapper > *{
    max-width: 45rem;
  }

  &.center .message-list-inner-wrapper > * {
    margin: auto;
  }
}

#video-chat #message-wrapper .ant-comment-content-author .ant-comment-content-author-time {
  margin-left: auto;
}

svg > * {
  shape-rendering: geometricPrecision;
}

#agenda-header {
  color: #fff;
  text-align: center;
  margin: 1rem 0;

  .ant-typography {
    color: white;
  }
}

#agenda-table {
  margin: auto;
  text-align: left;

  thead {
    background: white;
    color: $gradient-dark;
    font-weight: bold;
  }

  td.highlight {
    color: rgb(35, 198, 246);
  }

  td, th {
    padding-left: 1rem;
    padding-top: .3rem;
    padding-bottom: .3rem;
  }

  td.agenda-row {
    background: white;
    color: $gradient-dark;
    text-align: center;
    margin: 1rem 0;
    font-weight: bold;
  }

  sup {
    font-size: 50%;
  }
}

.ant-card-head-title {
  white-space: unset !important;
  text-overflow: unset !important;
}

.christmas-card {
  border: 1px solid #f0f0f0;
  box-shadow: inset 0 0 6px 3px hsla(0, 0%, 100%, .4);
  background: linear-gradient(145deg,hsla(0,0%,100%,0) 58%,hsla(0,0%,100%,0) 62%,hsla(0,0%,100%,.1) 0,hsla(0,0%,100%,.1) 69%,hsla(0,0%,100%,0) 0,hsla(0,0%,100%,0) 70%,hsla(0,0%,100%,.1) 0,hsla(0,0%,100%,.1) 77%,hsla(0,0%,100%,0) 0,hsla(0,0%,100%,0));
  margin-top: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f0f0f0 !important;

  img {
    width: 13rem;
    margin: 1rem;
    margin-right: 1rem;
    display: block;
  }

  h2.ant-typography {
    color: #f0f0f0;
    margin-left: 2rem;
    margin-bottom: 0;
    font-size: 4rem;
  }
}

#main-dashboard {
  height: calc(70vh - 80px);
}